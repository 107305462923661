
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import XmlController from "@/plugins/uyap-plugin/takip_ac/XmlController";
import { VForm } from "@/types";
import * as xml2js from "xml2js";
import { JobType } from "@/enum/JobType";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import { ProgramPaths } from "@/enum/ProgramPaths";

interface JobItem {
  id: number;
  file_name: string;
  created_at: string;
  file_count: number;
  showProgress?: boolean;
  value?: number;
}

@Component({
  components: { DeleteDialog }
})
export default class AktarView extends Vue {
  @Ref("form") readonly xmlForm!: VForm;
  valid = false;
  onFly = false;
  xml: File | null = null;
  items: JobItem[] = [];
  loadItems = false;
  interval: any = {};
  value = 60;
  showProgress = false;
  fileName: string = "";
  uploadProgress = 0;
  alert = false;
  alertMessage = "";
  showDialog = false;
  showSecondaryDialog = false;
  tab = 0;
  countdown: number = 120;
  timer: number | null = null;
  progress: number = 100;

  headers = [
    { text: "İşlem No", value: "id" },
    { text: "Dosya Adı", value: "file_name" },
    { text: "Oluşturulma Tarihi", value: "created_at" },
    { text: "Dosya Sayısı", value: "file_count" },
    { text: "İşlemler", value: "actions", align: "end" }
  ];

  mounted() {
    this.load();
    window.addEventListener("beforeunload", this.preventNavigation);
    window.addEventListener("click", this.showSnackbarOnInteraction);
  }

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNavigation);
    window.removeEventListener("click", this.showSnackbarOnInteraction);
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  @Watch("xml")
  onXmlChanged(newValue: File | null, oldValue: File | null) {
    if (newValue) {
      this.fileName = newValue.name;
      this.autoUpload();
    }
  }

  @Watch("showDialog")
  onShowDialogChanged(newVal: boolean) {
    if (newVal) {
      this.startCountdown();
    } else {
      this.resetCountdown();
    }
  }

  async load() {
    this.loadItems = true;
    try {
      const [response, evrak] = await Promise.all([
        this.$http.get("/api/v1/job?type_id=2"),
        this.$http.get("/api/v1/job?type_id=3")
      ]);
      // @ts-ignore
      this.items = [...response, ...evrak];
    } catch (error) {
      console.error('Error loading items:', error);
    } finally {
      this.loadItems = false;
    }
  }

  setProgress(item: JobItem) {
    this.items.forEach((item) => {
      this.$set(item, "showProgress", false);
      this.$set(item, "value", 60);
    });

    this.$set(item, "showProgress", true);
    const interval = setInterval(() => {
      if (item.value === 0) {
        clearInterval(interval);
        item.showProgress = false;
      }
      item.value! -= 1;
    }, 1000);
  }

  fileRule(file: File): boolean | string {
    if (file) {
      let uzanti = file.name.split(".").pop();
      if (["xml", "XML"].indexOf(uzanti!) >= 0) return true;
      else return "Sadece xml dosya yüklenebilir.";
    } else return "Lütfen bu alanı doldurun.";
  }

  bolArray(dizi: Array<any>, boyut: number): Array<Array<any>> {
    let sonuc: Array<Array<any>> = [];
    for (let i = 0; i < dizi.length; i += boyut) {
      sonuc.push(dizi.slice(i, i + boyut));
    }
    return sonuc;
  }

  async xmlToJson(xmlData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const parser = new xml2js.Parser({
        explicitArray: false,
        mergeAttrs: true,
        emptyTag: null
      });
      parser.parseString(xmlData, (err: any, result: any) => {
        if (err) {
          console.error("XML çevrim hatası:", err);
          reject(err);
        } else {
          resolve(result);
        }
      });
    });
  }

  detay(item: JobItem) {
    this.$router.push(ProgramPaths.ofispro + "/aktar-detay/" + item.id);
  }

  onFileChange(file: File) {
    if (file) {
      this.fileName = file.name;
      this.autoUpload();
    }
  }

  onDrop(event: DragEvent) {
    const files = event.dataTransfer!.files;
    if (files.length) {
      this.xml = files[0];
      this.autoUpload();
    }
  }

  preventNavigation(event: BeforeUnloadEvent) {
    if (this.onFly) {
      event.preventDefault();
      event.returnValue = "";
    }
  }

  showSnackbarOnInteraction(event: Event) {
    if (this.onFly) {
      event.preventDefault();
    }
  }

  autoUpload() {
    if (this.tab === 0) {
      this.xmlAktar();
    } else if (this.tab === 1) {
      this.xmlEvrakAktar();
    }
  }

  async readXmlWithTimeout(file: any, timeout:any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const timer = setTimeout(() => {
        reader.abort();
        reject(new Error("Dosya okuma zaman aşımına uğradı."));
      }, timeout);

      reader.onload = () => {
        clearTimeout(timer);
        resolve(reader.result);
      };

      reader.onerror = () => {
        clearTimeout(timer);
        reject(new Error("Dosya okuma hatası."));
      };

      reader.readAsText(file);
    });
  }

  showBrokenXmlDialog() {
    this.alertMessage = "Bozuk XML dosyası yüklenemedi. Lütfen dosyanızı kontrol edin ve tekrar deneyin.";
    this.alert = true;
  }

  async postRequestWithRetry(url, data, delayMs = 5000) {
    while (true) {
      try {
        const response = await this.$http.post(url, data);
        if (response && response.data && response.data.args && response.data.args.length > 0) {
          return response.data;
        }
        throw new Error("No response args or args is empty");
      } catch (error) {
        console.error("Error in postRequestWithRetry:", error);
        await this.delay(delayMs);
      }
    }
  }

  async delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async xmlAktar() {
    if (this.onFly) {
      console.warn("Uyarı: Bir işlem zaten devam ediyor.");
      return;
    }

    if (!this.xmlForm.validate()) {
      console.warn("Uyarı: Form geçersiz.");
      return;
    }

    try {
      this.onFly = true;
      this.uploadProgress = 0;
      console.log("Başlıyor: XML dosyası okunacak");

      if (!this.xml) {
        console.warn("Uyarı: XML dosyası yüklenmedi.");
        this.onFly = false;
        return;
      }

      let xml;
      try {
        xml = await this.readXmlWithTimeout(this.xml, 60000); // 60 saniye zaman aşımı
      } catch (error) {
        this.showBrokenXmlDialog();
        this.onFly = false;
        return;
      }
      console.log("XML dosyası başarıyla okundu");

      let data;
      try {
        data = (await this.xmlToJson(xml)).exchangeData;
        if (!data) {
          throw new Error("Geçersiz XML yapısı: exchangeData bulunamadı.");
        }
      } catch (error) {
        console.error("XML verisi JSON'a dönüştürülürken hata oluştu:", error);
        this.showBrokenXmlDialog();
        this.onFly = false;
        return;
      }
      console.log("XML verisi JSON'a dönüştürüldü", data);

      if (!Array.isArray(data.dosyalar.dosya)) {
        data.dosyalar.dosya = [data.dosyalar.dosya];
      }
      let dosyalar: Array<any> = data.dosyalar.dosya;

      // Evrakları çıkarıyoruz
      dosyalar = dosyalar.map(dosya => {
        const { evraklar, ...rest } = dosya;
        return rest;
      });
      console.log("Evraklar çıkarıldı");

      for (let dosya of dosyalar) {
        if (dosya.hacizler?.haciz && !Array.isArray(dosya.hacizler.haciz)) {
          dosya.hacizler.haciz = [dosya.hacizler.haciz];
        } else {
          dosya.hacizler = dosya.hacizler || { haciz: [] };
        }

        if (dosya.masrafCesitleri?.masrafCesidi && !Array.isArray(dosya.masrafCesitleri.masrafCesidi)) {
          dosya.masrafCesitleri.masrafCesidi = [dosya.masrafCesitleri.masrafCesidi];
        } else {
          dosya.masrafCesitleri = dosya.masrafCesitleri || { masrafCesidi: [] };
        }

        if (dosya.masraflar?.masraf && !Array.isArray(dosya.masraflar.masraf)) {
          dosya.masraflar.masraf = [dosya.masraflar.masraf];
        } else {
          dosya.masraflar = dosya.masraflar || { masraf: [] };
        }

        if (dosya.satislar?.satis && !Array.isArray(dosya.satislar.satis)) {
          dosya.satislar.satis = [dosya.satislar.satis];
        } else {
          dosya.satislar = dosya.satislar || { satis: [] };
        }

        if (dosya.tahsilatlar?.tahsilat && !Array.isArray(dosya.tahsilatlar.tahsilat)) {
          dosya.tahsilatlar.tahsilat = [dosya.tahsilatlar.tahsilat];
        } else {
          dosya.tahsilatlar = dosya.tahsilatlar || { tahsilat: [] };
        }

        if (dosya.tebligatlar?.tebligat && !Array.isArray(dosya.tebligatlar.tebligat)) {
          dosya.tebligatlar.tebligat = [dosya.tebligatlar.tebligat];
        } else {
          dosya.tebligatlar = dosya.tebligatlar || { tebligat: [] };
        }

        if (dosya.notlar?.not && !Array.isArray(dosya.notlar.not)) {
          dosya.notlar.not = [dosya.notlar.not];
        } else {
          dosya.notlar = dosya.notlar || { not: [] };
        }
      }
      console.log("Dosya alt elemanları düzenlendi");

      let bolunmusDosyalar: Array<Array<any>> = this.bolArray(dosyalar, 25); // 25 dosyalık partiler halinde bölme
      console.log("Dosyalar bölündü", bolunmusDosyalar);

      const totalChunks = bolunmusDosyalar.length;
      let uploadedChunks = 0;

      for (let bolunmusDosya of bolunmusDosyalar) {
        if (!bolunmusDosya || bolunmusDosya.length === 0) {
          continue;
        }
        let dosyaArgs = bolunmusDosya.map(dosya => ({ ...dosya, kart_no: dosya.kart_no }));
        let success = false;
        let retries = 3;
        while (retries > 0 && !success) {
          try {
            let response = await this.$http.post('/api/v1/job', {
              file_name: this.fileName,
              type_id: JobType.IcwAktar,
              status: "WAITING",
              args: dosyaArgs
            });
            success = true;
            console.log("Başarıyla gönderildi:", response);
          } catch (error) {
            retries--;
            console.error("Gönderim hatası:", error);
            if (retries === 0) {
              this.$toast.error("Dosya parçası gönderilirken hata oluştu: " + (error.response ? error.response.data.error : error.message));
            }
          }
        }
        uploadedChunks++;
        this.uploadProgress = Math.round((uploadedChunks / totalChunks) * 100);
        console.log("Yükleme ilerlemesi:", this.uploadProgress);
      }

      await this.load();
      console.log("Yüklenen dosyalar güncellendi");

      if (this.items.length > 0) {
        this.setProgress(this.items[this.items.length - 1]);
      }
      this.$toast.success("Xml'den İcrapro'ya aktarma işlemi sıraya alındı.");
      console.log("Aktarma işlemi tamamlandı");
      this.showDialog = true;
    } catch (e) {
      console.error("Hata oluştu:", e);
      this.$toast.error("Xml'den İcrapro'ya aktarma işleminde hata oluştu. " + e.message);
    } finally {
      this.onFly = false;
    }
  }

  async xmlEvrakAktar() {
    if (this.onFly || !this.xmlForm.validate()) {
      if (!this.xmlForm.validate()) {
        console.warn("Uyarı: Form geçersiz.");
      }
      if (this.onFly) {
        console.warn("Uyarı: Bir işlem zaten devam ediyor.");
      }
      return;
    }

    try {
      this.onFly = true;
      this.uploadProgress = 0;
      console.log("Başlıyor: Evrak XML dosyası okunacak");

      if (!this.xml) {
        console.warn("Uyarı: Evrak XML dosyası yüklenmedi.");
        this.onFly = false;
        return;
      }

      let xml;
      try {
        xml = await this.readXmlWithTimeout(this.xml, 60000); // 60 saniye zaman aşımı
      } catch (error) {
        this.showBrokenXmlDialog();
        this.onFly = false;
        return;
      }
      console.log("Evrak XML dosyası başarıyla okundu");

      let data;
      try {
        data = (await this.xmlToJson(xml)).exchangeData;
        if (!data) {
          throw new Error("Geçersiz XML yapısı: exchangeData bulunamadı.");
        }
      } catch (error) {
        console.error("XML verisi JSON'a dönüştürülürken hata oluştu:", error);
        this.showBrokenXmlDialog();
        this.onFly = false;
        return;
      }
      console.log("Evrak XML verisi JSON'a dönüştürüldü", data);

      let evraklar: Array<any> = [];
      if (Array.isArray(data.dosyalar.dosya)) {
        for (let dosya of data.dosyalar.dosya) {
          if (dosya.evraklar && dosya.evraklar.evrak) {
            if (!Array.isArray(dosya.evraklar.evrak)) {
              dosya.evraklar.evrak = [dosya.evraklar.evrak];
            }
            for (let evrak of dosya.evraklar.evrak) {
              evrak.kart_no = dosya.kart_no;
              evraklar.push(evrak);
            }
          }
        }
      } else if (data.dosyalar.dosya.evraklar && data.dosyalar.dosya.evraklar.evrak) {
        if (!Array.isArray(data.dosyalar.dosya.evraklar.evrak)) {
          data.dosyalar.dosya.evraklar.evrak = [data.dosyalar.dosya.evraklar.evrak];
        }
        for (let evrak of data.dosyalar.dosya.evraklar.evrak) {
          evrak.kart_no = data.dosyalar.dosya.kart_no;
          evraklar.push(evrak);
        }
      }
      console.log("Evraklar toplandı", evraklar);

      const chunkSize = 1;
      let bolunmusEvraklar: Array<Array<any>> = this.bolArray(evraklar, chunkSize);
      console.log("Evraklar bölündü", bolunmusEvraklar);

      const totalChunks = bolunmusEvraklar.length;
      let uploadedChunks = 0;

      for (const bolunmusEvrak of bolunmusEvraklar) {
        const args = bolunmusEvrak.map(evrak => ({
          ...evrak,
          kart_no: evrak.kart_no
        }));

        const payload = {
          file_name: this.fileName,
          type_id: JobType.IcwAktarDosya,
          status: "WAITING",
          args: args
        };

        try {
          await this.postRequestWithRetry('/api/v1/job', payload);
          console.log("Evrak parçası başarıyla gönderildi");
        } catch (error) {
          console.error("Gönderim hatası:", error);
          this.$toast.error("Evrak parçası gönderilirken hata oluştu: " + (error.response ? error.response.data.error : error.message));
          this.showBrokenXmlDialog();
          this.onFly = false;
          return;
        }

        uploadedChunks++;
        this.uploadProgress = Math.round((uploadedChunks / totalChunks) * 100);
        console.log("Yükleme ilerlemesi:", this.uploadProgress);
      }

      await this.load();
      console.log("Yüklenen evraklar güncellendi");

      if (this.items.length > 0) {
        this.setProgress(this.items[this.items.length - 1]);
      }

      this.$toast.success("Evrak aktarma işlemi sıraya alındı.");
      console.log("Evrak aktarma işlemi tamamlandı");
      this.showSecondaryDialog = true;
      this.tab = 0;
    } catch (e) {
      console.error(e);
      this.$toast.error("Evrak aktarma işleminde hata oluştu. " + e.message);
    } finally {
      this.onFly = false;
    }
  }

  async continueTransfer() {
    this.showDialog = false;
    this.tab = 1;
    await this.$nextTick();
    this.xmlEvrakAktar();
  }

  dismissFirstDialog() {
    this.showDialog = false;
    this.showSecondaryDialog = true;
  }

  async anotherProcess() {
    this.showSecondaryDialog = false;
    // Burada başka bir işlem başlatabilirsiniz
  }

  goHome() {
    this.showSecondaryDialog = false;
    this.$router.push('/ofispro');
  }

  startCountdown() {
    this.countdown = 120;
    this.progress = 100;
    this.timer = setInterval(() => {
      this.countdown--;
      this.progress = (this.countdown / 120) * 100;
      if (this.countdown <= 0) {
        clearInterval(this.timer!);
        this.autoDismiss();
      }
    }, 1000);
  }

  resetCountdown() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.progress = 100;
  }

  autoDismiss() {
    this.showDialog = false;
    this.showSecondaryDialog = true;
  }
}
